exports.shouldUpdateScroll = ({pathname, prevRouterProps}) => {

    if (!prevRouterProps) {
        return true;
    }

    const pathParts = pathname.split("/");
    const prevPathParts = prevRouterProps.location.pathname.split("/");

    // Disabling automatic scroll management on pages with modals
    const disableFor = [
        "research-and-development",
        "about"
    ];
    if (
        (pathParts[2] && disableFor.includes(pathParts[1])) ||
        (prevPathParts[2] && disableFor.includes(prevPathParts[1]))
    ) {
        return false;
    }

    return true;

};
