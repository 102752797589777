import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import classNames from "classnames";

import * as classes from "./AboutTeamMemberLinkTemplate.module.scss";
import PreviewCompatibleImage from "../../components/helpers/PreviewCompatibleImage";

export default function AboutTeamMemberLinkTemplate({className, slug, name, post, photo}) {

    const parts = name.trim().split(/\s+/);
    const shortName = parts.length >= 2 ? `${parts[0]} ${parts[1]}` : name;

    const children = (
        <>
            <PreviewCompatibleImage
                className={classes.photo}
                image={photo}
                alt={name} />
            <span className={classes.name}>{shortName}</span>{" "}
            {post}
        </>
    );

    return slug ? (
        <Link
            className={classNames(classes.container, className)}
            to={`/about/team/${slug}`}>{children}</Link>
    ) : (
        <div className={classNames(classes.container, className)}>{children}</div>
    );

}

AboutTeamMemberLinkTemplate.propTypes = {
    className: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    photo: PropTypes.object.isRequired
};
