// extracted by mini-css-extract-plugin
export var outerContainer = "ImageGalleryModal-module--outer-container--wataf";
export var outerContainerVisible = "ImageGalleryModal-module--outer-container-visible--15mIL";
export var innerContainer = "ImageGalleryModal-module--inner-container--PSoBY";
export var innerContainerClickable = "ImageGalleryModal-module--inner-container-clickable--WcUQh";
export var closeButton = "ImageGalleryModal-module--close-button--6iySf";
export var navButton = "ImageGalleryModal-module--nav-button--beEfB";
export var navButtonHover = "ImageGalleryModal-module--nav-button-hover--cbAGY";
export var navButtonPrevious = "ImageGalleryModal-module--nav-button-previous--81ttY";
export var navButtonNext = "ImageGalleryModal-module--nav-button-next--wj2+5";
export var imageContainer = "ImageGalleryModal-module--image-container--A4LD+";
export var image = "ImageGalleryModal-module--image--BQYTf";