import React from "react";
import PropTypes from "prop-types";
import {getImage, GatsbyImage as Img} from "gatsby-plugin-image";

export default function PreviewCompatibleImage({className, image, alt}) {
    return image.childImageSharp ?
        <Img
            className={className}
            image={getImage(image)}
            alt={alt} /> :
        <img
            className={className}
            src={image.url}
            alt={alt} />;

};

PreviewCompatibleImage.propTypes = {
    className: PropTypes.string,
    image: PropTypes.object.isRequired,
    alt: PropTypes.string
};
