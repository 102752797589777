import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./Egg.module.scss";

export default function Egg({
    className, flipped = false, withFilledBottomRightCorner = false, ...props
}) {
    return (
        <div
            className={classNames(classes.container,
                flipped && classes.containerFlipped,
                withFilledBottomRightCorner && classes.containerWithFilledBottomRightCorner,
                className)}
            {...props} />
    );
}

Egg.propTypes = {
    className: PropTypes.string,
    flipped: PropTypes.bool,
    withFilledBottomRightCorner: PropTypes.bool
};
