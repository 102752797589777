import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useEventListener from "@use-it/event-listener";

import * as classes from "./NavSection.module.scss";
import ChevronDown from "../common/img/chevron-down.svg";

export default function NavSection({className, label, children}) {

    const [fixed, setFixed] = useState(false);
    const dropdown = useRef();

    useEventListener("click", event => {
        if (fixed && !dropdown.current.contains(event.target)) {
            setFixed(false);
        }
    });

    function handleToggle() {
        if (!fixed) setTimeout(() => setFixed(true), 0);
        else setFixed(false);
    }

    return (
        <div className={classNames(classes.container, fixed && classes.fixed, className)}>
            <button className={classes.toggle} onClick={handleToggle}>
                <ChevronDown className={classes.chevronDown} />
                {label}
            </button>
            <div ref={dropdown} className={classes.dropdown}>{children}</div>
        </div>
    );

}

NavSection.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired
};
