import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./VerticalEgg.module.scss";

export default function VerticalEgg({className, ...props}) {
    return (
        <div
            className={classNames(classes.container, className)}
            {...props} />
    );
}

VerticalEgg.propTypes = {
    className: PropTypes.string
};
