import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {GatsbyImage as Img} from "gatsby-plugin-image";
import classNames from "classnames";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

import {useIsLandscape, useIsMobile} from "../../util/responsiveness";
import getScrollbarWidth from "../../util/getScrollbarWidth";

import * as classes from "./ImageGalleryModal.module.scss";
import TimesLarge from "./img/times-large.svg";
import ChevronLeftLarge from "./img/chevron-left-large.svg";
import ChevronRightLarge from "./img/chevron-right-large.svg";

export default function ImageGalleryModal({visible, onClose, images, imageAlts, selectedImageIndex, onImageSelect}) {

    /** @type React.MutableRefObject<HTMLDivElement|undefined> */
    const outerContainer = useRef();

    useEffect(() => {
        if (visible) {
            const outerContainerElement = outerContainer.current;
            disableBodyScroll(outerContainerElement);
            outerContainerElement.style.removeProperty("margin-right");
            outerContainerElement.style.removeProperty("margin-bottom");
            return () => {
                enableBodyScroll(outerContainerElement);
                if (document.documentElement.scrollHeight > document.documentElement.clientHeight) {
                    outerContainerElement.style.setProperty("margin-right", `${-getScrollbarWidth()}px`);
                }
                if (document.documentElement.scrollWidth > document.documentElement.clientWidth + getScrollbarWidth()) {
                    outerContainerElement.style.setProperty("margin-bottom", `${-getScrollbarWidth()}px`);
                }
            };
        }
        return undefined;
    }, [visible]);

    /** @type React.MutableRefObject<HTMLDivElement|undefined> */
    const innerContainer = useRef();

    function handleOuterContainerClick(event) {
        if (event.target === outerContainer.current) {
            onClose();
        }
    }

    function selectPrevious() {
        onImageSelect((selectedImageIndex - 1 + images.length) % images.length);
    }

    function selectNext() {
        onImageSelect((selectedImageIndex + 1) % images.length);
    }

    const [navButtonHover, setNavButtonHover] = useState(undefined);

    function handleImageContainerClick({target, clientX}) {
        if (target.classList.contains(classes.navButton)) return;
        if (clientX < document.documentElement.clientWidth / 2) selectPrevious();
        else selectNext();
    }

    function handleImageContainerMouseMove({target, clientX}) {
        if (outerContainer.current.contains(target)) {
            if (target.classList.contains(classes.navButton)) return;
            const value = clientX < document.documentElement.clientWidth / 2 ? "PREVIOUS" : "NEXT";
            if (value !== navButtonHover) setNavButtonHover(value);
        }
    }

    function handleImageContainerMouseLeave({target}) {
        if (outerContainer.current.contains(target)) {
            setNavButtonHover(undefined);
        }
    }

    const isMobile = useIsMobile();
    const isLandscape = useIsLandscape();

    /** @type React.MutableRefObject<HTMLDivElement|undefined> */
    const imageContainer = useRef();

    // I concluded that the easiest way to make the image fit the container is to use JS.
    // This way I don't have to mess too much with gatsby-plugin-image's own markup and CSS.
    useLayoutEffect(() => {
        if (selectedImageIndex == null) return undefined;
        function handleResize() {
            const {width, height} = images[selectedImageIndex];
            imageContainer.current.style.removeProperty("width");
            imageContainer.current.style.removeProperty("height");
            if (isMobile) {
                if (isLandscape) {
                    imageContainer.current.style.setProperty("width",
                        imageContainer.current.offsetHeight * width / height + "px");
                } else {
                    imageContainer.current.style.setProperty("height",
                        imageContainer.current.offsetWidth * height / width + "px");
                }
            }
            const image = imageContainer.current.children[0];
            const scaleFactor = Math.min(imageContainer.current.offsetWidth / width,
                imageContainer.current.offsetHeight / height);
            image.style.width = width * scaleFactor + "px";
            image.style.height = height * scaleFactor + "px";
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [images, selectedImageIndex, isMobile, isLandscape]);

    return (
        <div
            ref={outerContainer}
            className={classNames(classes.outerContainer, visible && classes.outerContainerVisible)}
            onClick={handleOuterContainerClick}>
            <div
                ref={innerContainer}
                className={classNames(classes.innerContainer,
                    images.length > 1 && classes.innerContainerClickable)}
                {...(images.length > 1 ? {
                    onClick: handleImageContainerClick,
                    onMouseMove: handleImageContainerMouseMove,
                    onMouseOver: handleImageContainerMouseMove,
                    onMouseOut: handleImageContainerMouseLeave
                } : {})}>
                <button
                    className={classNames(classes.navButton, classes.navButtonPrevious,
                        navButtonHover === "PREVIOUS" && classes.navButtonHover)}
                    type="button"
                    disabled={images.length === 1}
                    aria-label="Закрыть"
                    onClick={selectPrevious}>
                    <ChevronLeftLarge />
                </button>
                <div
                    className={classes.imageContainer}
                    ref={imageContainer}>
                    {selectedImageIndex != null &&
                        <Img
                            className={classNames(classes.image)}
                            image={images[selectedImageIndex]}
                            alt={imageAlts[selectedImageIndex]} />}
                </div>
                <button
                    className={classNames(classes.navButton, classes.navButtonNext,
                        navButtonHover === "NEXT" && classes.navButtonHover)}
                    type="button"
                    disabled={images.length === 1}
                    onClick={selectNext}>
                    <ChevronRightLarge />
                </button>
                <button
                    className={classes.closeButton}
                    type="button"
                    onClick={onClose}>
                    <TimesLarge />
                </button>
            </div>
        </div>
    );

}

ImageGalleryModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    imageAlts: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedImageIndex: PropTypes.number,
    onImageSelect: PropTypes.func.isRequired
};
