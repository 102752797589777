import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import classNames from "classnames";

import * as classes from "./ButtonLink.module.scss";
import ChevronRight from "./img/chevron-right.svg";

export default function ButtonLink({className, variant = "OUTLINED", color = "ACCENT-1", children, ...props}) {

    let variantClassName;
    switch (variant) {
        case "OUTLINED": variantClassName = classes.variantOutlined; break;
        case "FILLED": variantClassName = classes.variantFilled; break;
        default: throw new Error("Unreachable code");
    }

    let colorClassName;
    switch (color) {
        case "ACCENT-1": colorClassName = classes.colorAccent1; break;
        case "ACCENT-2": colorClassName = classes.colorAccent2; break;
        default: throw new Error("Unreachable code");
    }

    return (
        <Link
            className={classNames(classes.container, variantClassName, colorClassName, className)}
            {...props}>
            {children}
            <ChevronRight className={classes.chevronRight} />
        </Link>
    );

}

ButtonLink.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(["OUTLINED", "FILLED"]),
    color: PropTypes.oneOf(["ACCENT-1", "ACCENT-2"])
};
