// extracted by mini-css-extract-plugin
export var ___gatsby = "Layout-module--___gatsby--UEoFX";
export var container = "Layout-module--container--bFbah";
export var header = "Layout-module--header--P0Ztj";
export var logoContainer = "Layout-module--logoContainer--4vXPB";
export var logo = "Layout-module--logo--witNy";
export var nav = "Layout-module--nav--bL-fo";
export var navItem = "Layout-module--nav-item--aeJox";
export var footer = "Layout-module--footer--Suv5t";
export var footerColumn = "Layout-module--footer-column--ew4gs";
export var footerNavColumn = "Layout-module--footer-nav-column--1sr-9";
export var footerTel = "Layout-module--footer-tel--pOVyR";
export var footerEmailAndCopyContainer = "Layout-module--footer-email-and-copy-container--++Njj";
export var footerEmail = "Layout-module--footer-email--T6p-B";
export var footerCopy = "Layout-module--footer-copy--jkU+E";