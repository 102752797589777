import React, {useState} from "react";
import PropTypes from "prop-types";
import {GatsbyImage as Img} from "gatsby-plugin-image";
import classNames from "classnames";

import ImageGalleryModal from "./ImageGalleryModal";

import * as classes from "./ImageGallery.module.scss";

export default function ImageGallery({imageThumbnails, images, imageAlts}) {

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState();

    function selectImage(index) {
        setSelectedImageIndex(index);
        setModalVisible(true);
    }

    return (
        <div className={classes.container}>
            {imageThumbnails.map((image, i) =>
                <button
                    key={i}
                    className={classes.thumbnailButton}
                    onClick={() => selectImage(i)}>
                    <Img
                        className={classNames(classes.thumbnailImage)}
                        image={image}
                        alt={imageAlts[i]} />
                </button>)}
            <ImageGalleryModal
                images={images}
                imageAlts={imageAlts}
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                selectedImageIndex={selectedImageIndex}
                onImageSelect={setSelectedImageIndex} />
        </div>
    );

}

ImageGallery.propTypes = {
    imageThumbnails: PropTypes.arrayOf(PropTypes.object).isRequired,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    imageAlts: PropTypes.arrayOf(PropTypes.string).isRequired
};
