import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import classNames from "classnames";

import * as classes from "./ResearchAndDevelopmentLink.module.scss";

export default function ResearchAndDevelopmentLink({className, contentClassName, title, slug, stageText}) {
    return (
        <Link
            className={classNames(classes.container, className)}
            to={`/research-and-development/${slug}`}>
            <span className={classNames(classes.content, contentClassName)}>
                <span className={classes.title}>{title}</span>{" "}
                <em className={classes.stageText}>Стадия: {stageText}</em>
            </span>
        </Link>
    );
}

ResearchAndDevelopmentLink.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    stageText: PropTypes.string.isRequired
};
