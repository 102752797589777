import React from "react";
import PropTypes from "prop-types";

import ButtonLink from "./ButtonLink";

import * as classes from "./SeeAlso.module.scss";

export default function SeeAlso({contentClassName, linkText, linkTo, children}) {
    return (
        <section className={classes.container}>
            <p className={contentClassName}>{children}</p>
            <ButtonLink className={classes.link} to={linkTo} variant="FILLED">{linkText}</ButtonLink>
        </section>
    );
}

SeeAlso.propTypes = {
    contentClassName: PropTypes.string,
    linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    linkTo: PropTypes.string.isRequired
};
