import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";

import AboutTeamMemberLinkTemplate from "../../templates/about/AboutTeamMemberLinkTemplate";

export default function AboutTeamMemberLink({className, slug, name, post, photo}) {
    return (
        <AboutTeamMemberLinkTemplate
            className={className}
            slug={slug}
            name={name}
            post={post}
            photo={photo} />
    );
}

AboutTeamMemberLink.propTypes = {
    className: PropTypes.string,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    photo: PropTypes.object.isRequired
};

export const query = graphql`
    fragment AboutTeamMemberLink on TeamMember {
        id
        slug
        name
        post
        photo {
            childImageSharp {
                gatsbyImageData(
                    layout: CONSTRAINED,
                    width: 266,
                    height: 355,
                    sizes: "(max-width: 767px) 222px, 266px",
                    outputPixelDensities: [0.835, 1, 1.669, 2]
                )
            }            
        }
    }
`;
