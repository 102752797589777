import React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "@reach/router";

import {useIsMobile} from "../../util/responsiveness";
import SeeAlso from "../common/SeeAlso";
import Modal from "../common/Modal";
import ResearchAndDevelopmentLink from "./ResearchAndDevelopmentLink";

import * as classes from "./ResearchAndDevelopmentLayout.module.scss";

const STAGES = [
    "НИОКР",
    "Подготовка регистрационных документов",
    "Регистрация",
    "Коммерциализация"
];

const ITEMS = [{
    slug: "camls",
    title: "CAMLs",
    stage: 2
}, {
    slug: "liquid-biochip",
    title: "Жидкий биочип",
    stage: 1
}, {
    slug: "cell-biochip",
    title: "Клеточные биочипы для диагностики гемобластозов",
    stage: 1
}, {
    slug: "biochip-ser-2",
    title: "Биочип SER-2",
    stage: 2
}, {
    slug: "biochip-gyn",
    title: "Биочип GYN",
    stage: 2
}, {
    slug: "biochip-mam",
    title: "Биочип MAM",
    stage: 2
}];

export default function ResearchAndDevelopmentLayout({children}) {

    const {pathname} = useLocation();
    const pathParts = pathname.split("/");
    const isRoot = !pathParts[2];

    const isMobile = useIsMobile();

    return (
        <>
            <Helmet>
                <title>Исследования и разработки</title>
            </Helmet>
            <section>
                <h1>Пайплайн разработок</h1>
                <div className={classes.graphic}>
                    <ul className={classes.stages}>
                        <li>Название<br />разработки</li>
                        {STAGES.map((text, i) =>
                            <li key={i}>
                                {text}
                                {i === 2 &&
                                    <>
                                        {" "}
                                        <em>клинические и технические испытания</em>
                                    </>}
                            </li>)}
                    </ul>
                    <ul className={classes.items}>
                        {ITEMS.map(({title, slug, stage}) =>
                            <li key={slug} data-stage={stage}>
                                <ResearchAndDevelopmentLink
                                    className={classes.itemLink}
                                    contentClassName={classes.itemLinkContent}
                                    title={title}
                                    slug={slug}
                                    stageText={STAGES[stage - 1]} />
                            </li>)}
                    </ul>
                </div>
                <Modal
                    closeLinkTo="/research-and-development"
                    visible={!isRoot}>
                    {children}
                </Modal>
            </section>
            <SeeAlso
                contentClassName={classes.seeAlsoContent}
                linkText={<>Перейти {!isMobile && <br />}к продуктам</>}
                linkTo="/products">
                Чтобы вкладываться в новые идеи, необходимо усиленно поддерживать и&nbsp;развивать актуальные ресурсы компании.
                Взгляните на наши зарегистрированные продукты, которые помогают нам двигаться вперед.
            </SeeAlso>
        </>
    );

}
