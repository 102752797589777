import React from "react";

import Layout from "../components/layout/Layout";
import ResearchAndDevelopmentLayout from "../components/research-and-development/ResearchAndDevelopmentLayout";
import AboutLayout from "../components/about/AboutLayout";

export default function GlobalLayout({children, pageContext}) {

    switch (pageContext.layout) {
        case "researchAndDevelopment":
            children = (
                <ResearchAndDevelopmentLayout>{children}</ResearchAndDevelopmentLayout>
            );
            break;
        case "about":
            children = (
                <AboutLayout>{children}</AboutLayout>
            );
            break;
    }

    return (
        <Layout>{children}</Layout>
    );

}
