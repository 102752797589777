import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import classNames from "classnames";

import * as classes from "./NavLink.module.scss";

export default function NavLink({className, ...props}) {

    function getLinkProps({isCurrent}) {
        return {className: classNames(classes.container, isCurrent && classes.containerCurrent, className)};
    }

    return (
        <Link
            getProps={getLinkProps}
            {...props} />
    );

}

NavLink.propTypes = {
    className: PropTypes.string
};
