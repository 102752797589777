import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./NavSectionLink.module.scss";

import NavLink from "./NavLink";

export default function NavSectionLink({className, ...props}) {
    return (
        <NavLink
            className={classNames(classes.container, className)}
            {...props} />
    );
}

NavSectionLink.propTypes = {
    className: PropTypes.string
};
