import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {Link} from "gatsby";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

import NavLink from "./NavLink";
import NavSection from "./NavSection";
import NavSectionLink from "./NavSectionLink";

import * as classes from "./Header.module.scss";
import Logo from "../common/img/logo.svg";

export default function Header() {

    /** @type React.MutableRefObject<HTMLElement|undefined> */
    const nav = useRef();

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    useEffect(() => {
        if (mobileMenuVisible) {
            const navElement = nav.current;
            disableBodyScroll(navElement);
            return () => enableBodyScroll(navElement);
        }
    }, [mobileMenuVisible]);

    function handleNavClick(event) {
        let element = event.target;
        do {
            if (element.tagName === "A") {
                setMobileMenuVisible(false);
                break;
            }
            element = element.parentElement;
        }
        while (element.parentElement);
    }

    return (
        <header className={classes.container}>
            <Link
                className={classes.logoContainer}
                to="/"
                aria-label="Главная страница"
                onClick={handleNavClick}>
                <Logo className={classes.logo} />
            </Link>
            <button
                className={classNames(classes.navToggleButton, mobileMenuVisible && classes.navToggleButtonActivated)}
                aria-label="Переключить панель навигации"
                onClick={() => setMobileMenuVisible(value => !value)} />
            <nav
                ref={nav}
                className={classNames(classes.nav, mobileMenuVisible && classes.navMobileVisible)}
                onClick={handleNavClick}>
                <NavLink className={classes.navItem} to="/products">Продукты</NavLink>
                <NavLink className={classes.navItem} to="/products/tps-covid-19">COVID-19</NavLink>
                <NavLink className={classes.navItem} to="/research-and-development">Исследования и разработки</NavLink>
                <NavSection className={classes.navItem} label="Медиацентр">
                    <NavSectionLink to="/news">Новости</NavSectionLink>
                    <NavSectionLink to="/media">СМИ о нас</NavSectionLink>
                    <NavSectionLink to="/publications">Публикации</NavSectionLink>
                </NavSection>
                <NavSection className={classes.navItem} label="О нас">
                    <NavSectionLink to="/about">О компании</NavSectionLink>
                    <NavSectionLink to="/documents">Документы</NavSectionLink>
                    <NavSectionLink to="/contact-us">Контакты</NavSectionLink>
                </NavSection>
            </nav>
        </header>
    );

}
