import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import VerticalEgg from "./VerticalEgg";

import * as classes from "./VerticalShadowEgg.module.scss";

export default function VerticalShadowEgg({className, eggClassName, ...props}) {
    return (
        <div className={classNames(classes.container, className)}>
            <VerticalEgg className={eggClassName} {...props} />
        </div>
    );
}

VerticalShadowEgg.propTypes = {
    className: PropTypes.string,
    eggClassName: PropTypes.string
};
