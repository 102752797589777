import React from "react";
import {Helmet} from "react-helmet";
import {Link, withPrefix} from "gatsby";
import classNames from "classnames";

import Header from "./Header";
import Tel from "../common/Tel";
import Email from "../common/Email";

import * as classes from "./Layout.module.scss";

export default function Layout({children}) {
    return (
        <div className={classes.container}>

            <Helmet titleTemplate="%s – RusCell">

                <html lang="ru" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />

                <link rel="icon" type="image/x-icon" sizes="16x16 32x32 48x48" href={`${withPrefix("/")}assets/favicon.ico`} />
                <link rel="icon" type="image/svg+xml" href={`${withPrefix("/")}assets/icon.svg`} />
                <link rel="apple-touch-icon" href={`${withPrefix("/")}assets/icon-180.png`} />
                <link rel="manifest" href={`${withPrefix("/")}assets/site.webmanifest`} />
                <meta name="theme-color" content="#fff" />

                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap" />

            </Helmet>

            <Header />
            <main>
                {children}
            </main>
            <footer className={classes.footer}>
                <div className={classNames(classes.footerColumn, classes.footerNavColumn)}>
                    <h3>Наши разработки</h3>
                    <ul>
                        <li><Link to="/products">Продукты</Link></li>
                        <li><Link to="/products/tps-covid-19">COVID-19</Link></li>
                        <li><Link to="/research-and-development">Исследования <br />и разработки</Link></li>
                    </ul>
                </div>
                <div className={classNames(classes.footerColumn, classes.footerNavColumn)}>
                    <h3>Медиацентр</h3>
                    <ul>
                        <li><Link to="/news">Новости</Link></li>
                        <li><Link to="/media">СМИ о нас</Link></li>
                        <li><Link to="/publications">Публикации</Link></li>
                    </ul>
                </div>
                <div className={classNames(classes.footerColumn, classes.footerNavColumn)}>
                    <h3>О нас</h3>
                    <ul>
                        <li><Link to="/about">О компании</Link></li>
                        <li><Link to="/documents">Документы</Link></li>
                        <li><Link to="/contact-us">Контакты</Link></li>
                    </ul>
                </div>
                <div className={classes.footerColumn}>
                    <Tel className={classes.footerTel} />
                    <div className={classes.footerEmailAndCopyContainer}>
                        <Email className={classes.footerEmail} />
                        <p className={classes.footerCopy}>© RusCell, {new Date().getFullYear()}</p>
                    </div>
                </div>
            </footer>

        </div>
    );
};
