import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./Tel.module.scss";

export default function Tel({className}) {
    return (
        <a className={classNames(classes.container, className)} href="tel:+74951504402">+7 (495) 150-44-02</a>
    );
}

Tel.propTypes = {
    className: PropTypes.string
};
