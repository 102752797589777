import {useMediaQuery} from "@react-hook/media-query";

export const MOBILE_BREAKPOINT = 768;

const MOBILE_MEDIA_QUERY = `(max-width: ${MOBILE_BREAKPOINT - 1}px)`;
const LANDSCAPE_MEDIA_QUERY = "(orientation: landscape)";

export function useIsMobile() {
    return useMediaQuery(MOBILE_MEDIA_QUERY);
}

export function useIsLandscape() {
    return useMediaQuery(LANDSCAPE_MEDIA_QUERY);
}
