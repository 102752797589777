import React from "react";
import PropTypes from "prop-types";
import {GatsbyImage as Img} from "gatsby-plugin-image";
import classNames from "classnames";

import Card from "../common/Card";
import ShadowEgg from "../common/ShadowEgg";
import VerticalShadowEgg from "../common/VerticalShadowEgg";

import * as classes from "./AboutSection.module.scss";

export default function AboutSection({
    className, eggContainerClassName, imageClassName, title, image, imageAlt, verticalEgg = false, children
}) {

    const ShadowEggComponent = !verticalEgg ? ShadowEgg : VerticalShadowEgg;
    const additionalEggComponentProps = !verticalEgg ? {
        flipped: true,
        withFilledBottomRightCorner: true
    } : {};

    return (
        <Card className={classNames(classes.container, className)}>
            <ShadowEggComponent
                className={classNames(classes.eggContainer, verticalEgg && classes.eggVerticalContainer,
                    eggContainerClassName)}
                eggClassName={classNames(classes.egg, verticalEgg && classes.eggVertical)}
                {...additionalEggComponentProps}>
                <Img
                    className={classNames(classes.image, imageClassName)}
                    image={image}
                    alt={imageAlt} />
            </ShadowEggComponent>
            <div className={classes.contentContainer}>
                <h2>{title}</h2>
                {children}
            </div>
        </Card>
    );

}

AboutSection.propTypes = {
    className: PropTypes.string,
    eggContainerClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    imageAlt: PropTypes.string.isRequired,
    verticalEgg: PropTypes.bool
};
