import React, {useEffect, useLayoutEffect, useRef} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {useIsMobile} from "../../util/responsiveness";
import getScrollbarWidth from "../../util/getScrollbarWidth";

import * as classes from "./Marquee.module.scss";
import useEventListener from "@use-it/event-listener";

const VELOCITY_FORWARD = 0.1;
const BACKTRACK_TIME = 1000;
const VELOCITY_HOVER = 0.75;
const HOVER_SLOP = 50;
const CLICK_SCROLL_DISTANCE = 500;

function easeInOutQuad(t) {
    return t < 0.5 ? 8 * t ** 4 : 1 - (-2 * t + 2) ** 4 / 2;
}

export default function Marquee({className, pause = false, children}) {

    const isMobile = useIsMobile();

    /** @type React.MutableRefObject<HTMLDivElement|undefined> */
    const outerContainer = useRef();
    /** @type React.MutableRefObject<HTMLDivElement|undefined> */
    const scrollContainer = useRef();

    const forcePause = useRef(false);
    useEffect(() => {
        forcePause.current = pause;
    }, [pause]);

    const pointerPaused = useRef(false);
    const focusPaused = useRef(false);
    const backtracking = useRef(false);
    const hoverDirection = useRef(undefined);
    useLayoutEffect(() => {
        if (isMobile) return undefined;
        let time = Date.now();
        let backtrackTimeStart;
        let animationFrame;
        function step() {
            const currentTime = Date.now();
            const delta = currentTime - time;
            time = currentTime;
            if ((!pointerPaused.current && !focusPaused.current && !forcePause.current) || hoverDirection.current != null) {
                if (!backtracking.current || hoverDirection.current != null) {
                    const velocity = hoverDirection.current != null ?
                        hoverDirection.current * VELOCITY_HOVER :
                        VELOCITY_FORWARD;
                    scrollContainer.current.scrollBy(velocity * delta, 0);
                    const {scrollLeft, scrollWidth, offsetWidth} = scrollContainer.current;
                    if (scrollWidth > offsetWidth && scrollLeft + offsetWidth >= scrollWidth) {
                        backtracking.current = true;
                        backtrackTimeStart = Date.now();
                    }
                } else {
                    const t = (Date.now() - backtrackTimeStart) / BACKTRACK_TIME;
                    if (t >= 1) {
                        backtracking.current = false;
                    } else {
                        const {scrollWidth, offsetWidth} = scrollContainer.current;
                        scrollContainer.current.scrollTo((scrollWidth - offsetWidth) * easeInOutQuad(1 - t), 0);
                    }
                }
            } else if (backtracking.current) {
                backtracking.current = false;
            }
            animationFrame = requestAnimationFrame(step);
        }
        animationFrame = requestAnimationFrame(step);
        return () => cancelAnimationFrame(animationFrame);
    }, [isMobile]);

    /** @type React.MutableRefObject<HTMLButtonElement|undefined> */
    const scrollButtonLeft = useRef();
    /** @type React.MutableRefObject<HTMLButtonElement|undefined> */
    const scrollButtonRight = useRef();
    function handleResize() {
        const {scrollWidth, offsetWidth} = scrollContainer.current;
        if (scrollWidth > offsetWidth) {
            scrollContainer.current.style.setProperty("margin-bottom", `${-getScrollbarWidth()}px`);
        } else {
            scrollContainer.current.style.removeProperty("margin-bottom");
        }
    }
    useLayoutEffect(() => handleResize(), []);
    useEventListener("resize", handleResize);

    /** @type React.MutableRefObject<number|undefined> */
    const resetHoverDirectionTimeout = useRef();
    function handleOuterContainerMouseEnter(event) {
        if (outerContainer.current.contains(event.target)) {
            pointerPaused.current = true;
            clearTimeout(resetHoverDirectionTimeout.current);
        }
    }
    function handleOuterContainerMouseLeave(event) {
        if (outerContainer.current.contains(event.target)) {
            pointerPaused.current = false;
            resetHoverDirectionTimeout.current = setTimeout(() => {
                hoverDirection.current = undefined;
            }, 0);
        }
    }
    function handleOuterContainerMouseMove(event) {
        const {clientX, clientY} = event;
        const boxLeft = scrollButtonLeft.current.getBoundingClientRect();
        const boxRight = scrollButtonRight.current.getBoundingClientRect();
        if (
            clientX <= boxLeft.x + boxLeft.width + HOVER_SLOP &&
            clientY >= boxLeft.y && clientY <= boxLeft.y + boxLeft.height
        ) {
            hoverDirection.current = -1;
        } else if (
            clientX >= boxRight.x - HOVER_SLOP &&
            clientY >= boxRight.y && clientY <= boxRight.y + boxRight.height
        ) {
            hoverDirection.current = 1;
        } else {
            hoverDirection.current = undefined;
        }
    }

    function handleOuterContainerFocusIn(event) {
        if (outerContainer.current.contains(event.target)) focusPaused.current = true;
    }
    function handleOuterContainerFocusOut(event) {
        if (outerContainer.current.contains(event.target)) focusPaused.current = false;
    }

    function handleScrollButtonLeftClick() {
        scrollContainer.current.scrollBy({left: -CLICK_SCROLL_DISTANCE, behavior: "smooth"});
    }
    function handleScrollButtonRightClick() {
        scrollContainer.current.scrollBy({left: CLICK_SCROLL_DISTANCE, behavior: "smooth"});
    }

    return (
        <div
            ref={outerContainer}
            className={classNames(classes.outerContainer, className)}
            onMouseOver={handleOuterContainerMouseEnter}
            onMouseOut={handleOuterContainerMouseLeave}
            onMouseMove={handleOuterContainerMouseMove}
            onFocus={handleOuterContainerFocusIn}
            onBlur={handleOuterContainerFocusOut}>
            <div className={classes.innerContainer}>
                <button
                    ref={scrollButtonLeft}
                    className={classNames(classes.scrollButton, classes.scrollButtonLeft)}
                    onClick={handleScrollButtonLeftClick}>Прокрутить влево</button>
                <button
                    ref={scrollButtonRight}
                    className={classNames(classes.scrollButton, classes.scrollButtonRight)}
                    onClick={handleScrollButtonRightClick}>Прокрутить вправо</button>
                <div
                    ref={scrollContainer}
                    className={classes.scrollContainer}>
                    {children}
                </div>
            </div>
        </div>
    );

}

Marquee.propTypes = {
    className: PropTypes.string,
    pause: PropTypes.bool
};
