let scrollbarWidthMemoized;
export default function getScrollbarWidth() {

    if (scrollbarWidthMemoized != null) return scrollbarWidthMemoized;

    const outer = document.createElement("div");
    outer.style.overflow = "scroll";
    document.body.appendChild(outer);

    const inner = document.createElement("div");
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode.removeChild(outer);
    return scrollbarWidthMemoized = scrollbarWidth;

}
