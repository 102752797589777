import React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "@reach/router";
import {graphql, useStaticQuery} from "gatsby";
import {getImage} from "gatsby-plugin-image";

import {useIsMobile} from "../../util/responsiveness";
import Modal from "../common/Modal";
import Marquee from "../common/Marquee";
import AboutSection from "./AboutSection";
import AboutTeamMemberLink from "./AboutTeamMemberLink";
import ImageGallery from "../common/ImageGallery";

import * as classes from "./AboutLayout.module.scss";

export default function AboutLayout({children}) {

    const {pathname} = useLocation();
    const pathParts = pathname.split("/");
    const isRoot = !pathParts[2];

    const isMobile = useIsMobile();

    const data = useStaticQuery(graphql`
        query {
            mainImage1File: file(relativePath: {eq: "about/main-1.jpg"}) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 564, height: 436)
                }
            }
            mainImage2File: file(relativePath: {eq: "about/main-2.jpg"}) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 957, height: 1238)
                }
            }
            mainImage2MobileFile: file(relativePath: {eq: "about/main-2-mobile.jpg"}) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 564, height: 436)
                }
            }
            mainImage3File: file(relativePath: {eq: "about/main-3.jpg"}) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 564, height: 436)
                }
            }
            allTeamMember(
                sort: {fields: [index]}
            ) {
                edges {
                    node {
                        ...AboutTeamMemberLink                                
                    }
                }
            }
            allPhoto(
                sort: {fields: [index]}
            ) {
                edges {
                    node {
                        imageAlt
                        imageThumbnailFile: image {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED, width: 365, height: 206)
                            }            
                        }
                        imageFile: image {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED)
                            }            
                        }
                    }
                }
            }
        }
    `);

    const teamMemberEdges = data["allTeamMember"]["edges"];

    const photoEdges = data["allPhoto"]["edges"];
    const photoAlts = photoEdges.map(({node}) => node["imageAlt"]);
    const photoThumbnailFiles = photoEdges.map(({node}) => node["imageThumbnailFile"]);
    const photoFiles = photoEdges.map(({node}) => node["imageFile"]);

    return (
        <>
            <Helmet>
                <title>О нас</title>
            </Helmet>
            <section className={classes.topLevelSection}>
                <h1>О нас</h1>
                <AboutSection
                    className={classes.mainSection}
                    eggContainerClassName={classes.mainSection1EggContainer}
                    title="Деятельность"
                    image={getImage(data["mainImage1File"])}
                    imageAlt="Разработка и производство медицинских изделий для диагностики злокачественных новообразований">
                    <p>Компания RusCell основана в 2014 году. Мы — инновационная медицинская компания, объединившая в себе фундаментальную научно‑исследовательскую базу и современное биотехнологическое производство. Команда RusCell ведет полный цикл разработки медицинских изделий: от идеи и НИОКР до массового производства и продвижения.</p>
                </AboutSection>
                <AboutSection
                    className={classes.mainSection}
                    eggContainerClassName={!isMobile ?
                        classes.mainSection2VerticalEggContainer : classes.mainSection2HorizontalEggContainer}
                    imageClassName={classes.mainSection2VerticalEggImage}
                    title="Достижения"
                    image={getImage(!isMobile ?
                        data["mainImage2File"]: data["mainImage2MobileFile"])}
                    imageAlt="Цитологическое исследование и жидкостная цитология"
                    verticalEgg={!isMobile}>
                    <p>Продукты компании были высоко оценены на 29‑ом Европейском конгрессе по патологии в Амстердаме, отмечены в номинации «За разработку нового метода диагностики» на главной медицинской премии РФ «Призвание».</p>
                    <p>В 2020 году получено регистрационное удостоверение на транспортно‑питательные среды и запущено производство мощностью 1 миллион единиц продукции в месяц.</p>
                    <p>Также компания занимается развитием проекта по системе дистанционной морфологической экспресс‑диагностики, которая основана на выполнении преаналитического этапа в условиях ЦРБ, дистанционной консультации гистологических/цитологических стекол/биочипов в крупных региональных и федеральных лабораториях и создании цифрового архива. Реализованы пилотные проекты в Новгородской и Нижегородской областях.</p>
                    <p>В 2020 году запущен проект по оснащению Белгородской области системами дистанционной морфологической диагностики. В мае 2021 компания успешно завершила НИОКР по разработке тест‑систем для скрининга онкозаболеваний по крови с оценкой нового перспективного маркера CAML.</p>
                </AboutSection>
                <AboutSection
                    className={classes.mainSection}
                    eggContainerClassName={classes.mainSection3EggContainer}
                    title="Наше видение"
                    image={getImage(data["mainImage3File"])}
                    imageAlt="Команда ООО «РУССЭЛЛ»">
                    <p>Миссия нашей компании — продлить и улучшить жизнь многих людей, сделав диагностику ЗНО проще, быстрее и эффективнее. Мы обладаем сильным R&D бэкграундом в области онкодиагностики за счет имеющегося у компании научного задела и высокой кадровой компетенции. Сейчас мы разрабатываем много новых продуктов и активно занимаемся нашим технологическим развитием, вкладывая все ресурсы компании в перманентный поиск прогрессивных решений в нашей сфере.</p>
                </AboutSection>
            </section>
            <section className={classes.topLevelSection}>
                <h1>Наша команда</h1>
                <Marquee pause={!isRoot}>
                    {teamMemberEdges?.map(({node}) =>
                        <AboutTeamMemberLink
                            key={node.id}
                            className={classes.link}
                            {...node} />)}
                </Marquee>
                <Modal
                    closeLinkTo="/about"
                    visible={!isRoot}>
                    {children}
                </Modal>
            </section>
            <section>
                <h1>Производство</h1>
                <ImageGallery
                    imageThumbnails={photoThumbnailFiles.map(getImage)}
                    images={photoFiles.map(getImage)}
                    imageAlts={photoAlts} />
            </section>
        </>
    );

}
