import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./Email.module.scss";

export default function Email({className}) {
    return (
        <a className={classNames(classes.container, className)} href="mailto:info@ruscell.ru">info@ruscell.ru</a>
    );
}

Email.propTypes = {
    className: PropTypes.string
};
