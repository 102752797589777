import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Link, navigate} from "gatsby";
import classNames from "classnames";
import {Scrollbar as Scrollbars} from "react-scrollbars-custom";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

import getScrollbarWidth from "../../util/getScrollbarWidth";

import * as classes from "./Modal.module.scss";
import Times from "./img/times.svg";

export default function Modal({closeLinkTo, visible, children}) {

    useEffect(() => {
        if (visible) {
            const container = innerContainer.current;
            const contentContainer = scrollbars.current.scrollerElement;
            disableBodyScroll(contentContainer);
            container.style.removeProperty("margin-right");
            return () => {
                enableBodyScroll(contentContainer);
                container.style.setProperty("margin-right", `${-getScrollbarWidth()}px`);
            };
        }
        return undefined;
    }, [visible]);

    const [renderedChildren, setRenderedChildren] = useState(children);
    useEffect(() => {
        if (visible) {
            setRenderedChildren(children);
        }
    }, [visible, children]);

    /** @type React.MutableRefObject<HTMLDivElement|undefined> */
    const outerContainer = useRef();

    /** @type React.MutableRefObject<HTMLDivElement|undefined> */
    const innerContainer = useRef();

    function handleOuterContainerClick(event) {
        if (event.target === outerContainer.current) {
            // noinspection JSIgnoredPromiseFromCall
            navigate(closeLinkTo);
        }
    }

    function handleOuterContainerTransitionEnd(event) {
        if (
            event.target === outerContainer.current &&
            event.propertyName === "visibility" &&
            getComputedStyle(event.target).visibility === "hidden"
        ) {
            setRenderedChildren(null);
        }
    }

    /** @type React.MutableRefObject<Scrollbars|undefined> */
    const scrollbars = useRef();

    useLayoutEffect(() => {
        if (visible) {
            scrollbars.current?.scrollToTop();
        }
    }, [visible]);

    function renderCloseButton(className = undefined) {
        return (
            <Link
                className={classNames(classes.closeButton, className)}
                to={closeLinkTo}
                aria-label="Назад">
                <Times />
            </Link>
        );
    }

    return (
        <div
            ref={outerContainer}
            className={classNames(classes.outerContainer, visible && classes.outerContainerVisible)}
            onClick={handleOuterContainerClick}
            onTransitionEnd={handleOuterContainerTransitionEnd}>
            <div
                ref={innerContainer}
                className={classNames(classes.innerContainer, visible && classes.innerContainerVisible)}>
                {renderCloseButton()}
                <Scrollbars
                    ref={scrollbars}
                    contentProps={{
                        renderer: ({elementRef, className, ...props}) =>
                            <div
                                className={classNames(className, classes.contentContainer)}
                                ref={elementRef}
                                {...props} />
                    }}
                    trackYProps={{
                        renderer: ({elementRef, className, ...props}) =>
                            <div
                                className={classNames(className, classes.scrollbarTrack)}
                                ref={elementRef}
                                {...props} />
                    }}
                    thumbYProps={{
                        renderer: ({elementRef, className, ...props}) =>
                            <div
                                className={classNames(className, classes.scrollbarThumb)}
                                ref={elementRef}
                                {...props} />
                    }}
                    noScrollX={true}>
                    {renderCloseButton(classes.closeButtonMobile)}
                    {renderedChildren}
                </Scrollbars>
            </div>
        </div>
    );

}

Modal.propTypes = {
    closeLinkTo: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func
};
