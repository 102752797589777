import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Egg from "./Egg";

import * as classes from "./ShadowEgg.module.scss";

export default function ShadowEgg({className, eggClassName, ...props}) {
    return (
        <div className={classNames(classes.container, className)}>
            <Egg className={eggClassName} {...props} />
        </div>
    );
}

ShadowEgg.propTypes = {
    className: PropTypes.string,
    eggClassName: PropTypes.string
};
