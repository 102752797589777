exports.components = {
  "component---src-components-documents-documents-js": () => import("./../../../src/components/documents/Documents.js" /* webpackChunkName: "component---src-components-documents-documents-js" */),
  "component---src-components-media-media-js": () => import("./../../../src/components/media/Media.js" /* webpackChunkName: "component---src-components-media-media-js" */),
  "component---src-components-news-news-js": () => import("./../../../src/components/news/News.js" /* webpackChunkName: "component---src-components-news-news-js" */),
  "component---src-components-publications-publications-js": () => import("./../../../src/components/publications/Publications.js" /* webpackChunkName: "component---src-components-publications-publications-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-team-team-member-slug-js": () => import("./../../../src/pages/about/team/{TeamMember.slug}.js" /* webpackChunkName: "component---src-pages-about-team-team-member-slug-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-news-slug-js": () => import("./../../../src/pages/news/{News.slug}.js" /* webpackChunkName: "component---src-pages-news-news-slug-js" */),
  "component---src-pages-products-biochip-js": () => import("./../../../src/pages/products/biochip.js" /* webpackChunkName: "component---src-pages-products-biochip-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-tps-1-js": () => import("./../../../src/pages/products/tps-1.js" /* webpackChunkName: "component---src-pages-products-tps-1-js" */),
  "component---src-pages-products-tps-covid-19-js": () => import("./../../../src/pages/products/tps-covid-19.js" /* webpackChunkName: "component---src-pages-products-tps-covid-19-js" */),
  "component---src-pages-research-and-development-biochip-gyn-js": () => import("./../../../src/pages/research-and-development/biochip-gyn.js" /* webpackChunkName: "component---src-pages-research-and-development-biochip-gyn-js" */),
  "component---src-pages-research-and-development-biochip-mam-js": () => import("./../../../src/pages/research-and-development/biochip-mam.js" /* webpackChunkName: "component---src-pages-research-and-development-biochip-mam-js" */),
  "component---src-pages-research-and-development-biochip-ser-2-js": () => import("./../../../src/pages/research-and-development/biochip-ser-2.js" /* webpackChunkName: "component---src-pages-research-and-development-biochip-ser-2-js" */),
  "component---src-pages-research-and-development-camls-js": () => import("./../../../src/pages/research-and-development/camls.js" /* webpackChunkName: "component---src-pages-research-and-development-camls-js" */),
  "component---src-pages-research-and-development-cell-biochip-js": () => import("./../../../src/pages/research-and-development/cell-biochip.js" /* webpackChunkName: "component---src-pages-research-and-development-cell-biochip-js" */),
  "component---src-pages-research-and-development-index-js": () => import("./../../../src/pages/research-and-development/index.js" /* webpackChunkName: "component---src-pages-research-and-development-index-js" */),
  "component---src-pages-research-and-development-liquid-biochip-js": () => import("./../../../src/pages/research-and-development/liquid-biochip.js" /* webpackChunkName: "component---src-pages-research-and-development-liquid-biochip-js" */)
}

